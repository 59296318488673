import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleListHeading } from '../../components/organisms/ArticleListHeading';
import { SectionHeading } from '../../components/atoms/SectionHeading';
import {
  DocumentsCard,
  DocumentsCardNodeType,
} from '../../components/documents/DocumentsCard';

export const Head = () => (
  <HeadInner
    path='/documents'
    title='お役立ち資料'
    description='paild（ペイルド）のお役立ち資料、セミナー動画をご用意しました。導入検討などにご活用ください'
  />
);

const DocumentsPage: React.FC<{ data: GatsbyTypes.DocumentsQueryQuery }> = (
  props
) => {
  const allDocumentsList: DocumentsCardNodeType[] =
    props.data.allContentfulDocument.nodes
      .map((node) => {
        return {
          slug: node.slug!,
          title: node.title!,
          image: node.coverImage?.gatsbyImageData!,
          conversionButton: node.cvButton?.childMdx?.body,
          priority: node.priority,
        };
      })
      .filter((node) => node.slug !== null)
      .sort((a, b) => (a.priority! > b.priority! ? -1 : 1));

  const allVideosList: DocumentsCardNodeType[] =
    props.data.allContentfulVideo.nodes
      .map((node) => {
        return {
          slug: node.slug!,
          title: node.title!,
          image: node.coverImage?.gatsbyImageData!,
          priority: node.priority,
        };
      })
      .filter((node) => node.slug !== null)
      .sort((a, b) => (a.priority! > b.priority! ? -1 : 1));

  const links = [{ name: 'お役立ち資料', path: '/documents/' }];

  return (
    <Layout links={links}>
      <ArticleListHeading
        category='documents'
        title='お役立ち資料'
        description={[
          'お役立ち資料、サービス資料、セミナー動画をご用意しました。',
          ' 導入検討などにご活用ください。',
        ]}
      />
      <section css={DocumentsSectionStyle}>
        <SectionHeading>資料</SectionHeading>
        <div css={DocumentsListStyle}>
          {allDocumentsList.map((node, index) => {
            return <DocumentsCard key={index} node={node} type='documents' />;
          })}
        </div>
      </section>
      <section css={DocumentsSectionStyle}>
        <SectionHeading>セミナーアーカイブ動画</SectionHeading>
        <div css={DocumentsListStyle}>
          {allVideosList.map((node, index) => {
            return <DocumentsCard key={index} node={node} type='videos' />;
          })}
        </div>
      </section>
    </Layout>
  );
};

const DocumentsSectionStyle = css({
  width: '100%',
  maxWidth: 1120,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 40,
  gap: 60,

  '@media(max-width: 1119px)': {
    maxWidth: 710,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    padding: '0 17px',
  },
});

const DocumentsListStyle = css({
  display: 'grid',
  justifyItems: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  width: '100%',
  gap: 50,

  '@media(max-width: 1119px)': {
    maxWidth: 734,
    gridTemplateColumns: '1fr 1fr',
    gap: 40,
  },

  '@media(max-width: 768px)': {
    gridTemplateColumns: '1fr',
    gap: 20,
  },
});

export const query = graphql`
  query DocumentsQuery {
    allContentfulDocument {
      nodes {
        title
        slug
        coverImage {
          gatsbyImageData
        }
        cvButton {
          childMdx {
            body
          }
        }
        priority
      }
    }
    allContentfulVideo {
      nodes {
        title
        slug
        coverImage {
          gatsbyImageData
        }
        priority
      }
    }
  }
`;

export default DocumentsPage;
